const en = {
  translation: {
    // define translations below
    form: {
      required: 'This field is required.',
      email: 'The email format is invalid.',
      email_address1: 'Email Adress',
      password: {
        minLength: 'Password must be at least 8 characters.',
        confirm: 'Password confirmation does not match.',
        strong:
          'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
      invalid:
        'Authentication failed. Please check if your email address and password are correct.',
      user_locked: 'Your account is under lock. Please log in again later.',
      empty: 'Please input email address',
      incorrect_format: 'Please input correct format',
      unregistered_email_address: 'This email address is not registered.',
    },
    labels: {
      first_name: 'First Name',
      last_name: 'Last Name',
      login: 'Login',
      signup: 'Signup',
      remember_me: 'Remember Me',
      forgot_password: 'Forgot Password?',
      email_address: 'Email Address',
      email_address1: 'Email Address',
      password: 'Password',
      confirm_password: 'Confirm Password',
      submit: 'Submit',
      update: 'Update',
      save: 'Save',
      add_new: 'Add New',
      reset_password: 'Reset Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      new_password_confirmation: 'New Password Confirmation',
      enter_keyword: 'Enter Keyword',
      get_started: 'Get Started',
      integrations: 'Integrations',
      settings: 'Settings',
      documentation: 'Documentation',
      fullname: 'Fullname',
      inquiry_content: 'Inquiry Content',
      navigation: 'Navigation',
      resources: 'Resources',
      cancel: 'Cancel',
      action: 'Action',
      showPassword: 'Show Password',
      hidePassword: 'Hide Password',
      password_change: 'Password Changed',
      product_csv_download: 'CSV Download',
    },
    pages: {
      signup: {
        agree_to_terms: 'By clicking Register, you agree that you have read and agree to the',
        signup_complete:
          'A confirmation email has been sent to your inbox. Click the link to complete the registration process.',
        terms_conditions: 'Terms & Conditions',
        create_free_account: 'Create your Free Account',
      },
      forgot_password: {
        sub_heading: 'To recover your account, please enter your email address below.',
        success: 'Check your inbox for instructions on how to reset your password.',
      },
      reset_password: {
        sub_heading: 'Please enter your new password.',
        success: 'The password has been updated successfully.',
      },
      users: {
        user_created: 'The user has been created.',
        user_updated: 'User details have been updated.',
        user_deleted: 'User has been deleted.',
        add_user: 'Add User',
        edit_user: 'Edit User',
        delete_user: 'Delete user',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: 'Email Address',
        status: 'Status',
        delete_confirmation: 'Are you sure you want to delete the selected user?',
      },
      activate: {
        heading: 'Activate Account',
        subtitle: 'Set your password to activate your account.',
        activated: 'Your Account has been activated. You can now login to your account!',
      },
      dashboard: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        new_users: 'New Users',
        total_sales: 'Total Sales',
        total_orders: 'Total Orders',
      },
      not_found: {
        title: 'Page Not Found',
        sub_heading:
          'The page you are looking for may have been deleted or moved to another location.',
        back_to_top: 'Back to Top Page',
      },
      faq: {
        heading: 'FAQ',
        sub_heading: 'We have summarized the questions that customers inquire in a Q&A format.',
      },
      inquiry: {
        heading: 'Inquiry',
        sub_heading: 'If you would like to contact us, please fill out the form below.',
        success_message: 'Your inquiry has been sent.',
        failed_message: 'An error occurred while sending.',
      },
      profile: {
        heading: 'Edit Profile',
        sub_heading: 'Update your account information.',
        success_message: 'Details has been updated successfully.',
        failed_message: 'The update failed.',
      },
      landing: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        why_heading: 'Why use Base Template?',
        docker: {
          heading: 'Flexible Environment',
          description:
            'Eliminate the "it works on my machine" problem once and for all. Spend less time setting up environments, debugging environment-specific issues, and a more portable and easy-to-set-up codebase.',
        },
        react: {
          heading: 'Fast & Intuitive UI',
          description:
            'ReactJS is extremely intuitive to work with and provides interactivity to the layout of any UI. It is composable so we can utilize these components and integrate them into one place. Hence the code becomes a lot more maintainable and flexible.',
        },
        laravel: {
          heading: 'Powerful API',
          description:
            "Utilizing Laravel's API feature for easy backend API development. It's quick, simple with easy 3rd Party integrations and libraries.",
        },
        our_customers_heading: 'Our Clients',
        reviews_heading: 'What our clients say',
        see_all_reviews: 'See All Reviews',
        call_to_action: 'Accelerate your application development today!',
      },
      about: {
        main_heading: 'Our Story',
        sub_heading:
          'We work together to design, create and produce works that we are proud of for those we believe in.',
        meet_the_team: 'Meet the team',
        team_description:
          'Thoughtfulness, originality, and attention to detail are the basis for every product we design, build, and market.',
        our_mission: 'Our Mission',
        mission_description:
          'Our mission is to spread the excellence of technology with quality service and products valuing the business trend and proposition with people centric culture and behavior.',
        our_activities: 'Our Activities',
        activities_description: 'Never get so busy making a living that you forget to make a life.',
      },
      invoice: {
        csv: {
          export: {
            supplier_list_title: 'Supplier CSV Download',
            supplier_details_title: 'Supplier Details CSV Download',
            supplier_list_filename: 'Product Suppliers List.csv',
            supplier_details_filename: 'Product Suppliers Details.csv',
            list_button: 'Supplier CSV',
            details_button: 'Supplier Details CSV',
            period: 'Period',
            supplier: 'Supplier',
            product_name: 'Product Name',
            success: '',
            error: '',
            submit: 'Download',
          },
        },
      },
    },
    menu: {
      home: 'Home',
      about: 'About',
      inquiry: 'Inquiry',
      faq: 'FAQ',
      dashboard: 'Dashboard',
      users: 'Users',
      orders: 'Orders',
      reports: 'Reports',
      integrations: 'Integrations',
      profile: 'Profile',
      login: 'Login',
      logout: 'Logout',
      terms: 'Terms of ProductList',
      privacy_policy: 'Privacy Policy',
      documentation: 'Documentation',
      api_reference: 'API Reference',
      support: 'Documentation',
      styleguide: 'Styleguide',
    },
    table: {
      no_data: 'No data.',
      btn_renewal: 'Renewal',
    },
    office: {
      labels: {
        home: 'Home',
        preference: 'Preferences List',
        sub_domain_management: 'Subdomain Management',
        sub_domain_create: 'Create New Subdomain',
        button_create: 'Create New',
        stop: 'Stop',
        launch: 'Launch',
      },
      table: {
        labels: {
          sub_domain: 'Subdomain',
          client_name: 'Client name',
          registration_date: 'Registration date',
          status: 'Status',
          status_activated: 'Activited',
          status_suspended: 'Suspended',
          supension: 'Suspension will make the service unavailable. Do you want to stop?',
          do_you_want_start: 'Do you want to start?',
          do_you_want_to_stop: 'Do you want to stop?',
        },
      },
      form: {
        once_entered: 'Once entered, it cannot be changed. Input example: ashiriasgate',
        sub_domain_name: 'Sub domain name',
        office_set_up: 'Office set up',
        office_name: 'Office name',
        job_marketing_hokkaido: 'Job Marketing Hokkaido',
        use_for_display: 'Used for display within the system. Abbreviations are acceptable.',
        address: 'Address',
        phone_number: 'Phone number',
        official_name: 'Official name of the office',
        use_official_doc: 'Used for official documents. Please set the official name',
        formal_name: 'Formal name',
        corporate_number: 'Corporate number',
        invoice_support: 'Invoice Surpport',
        invoice_business: 'Invoice Business',
        term: 'Start of the term',
        user_preferences: 'User preferences',
        display_name: 'Display name',
        name: 'Your name',
        label_legal_name: 'Legal name',
        phonetic: 'Phonetic',
        legal_name: 'Please fill in the furigana of your legal name',
        user_it_for_login: 'User for login',
        user_id_for_login_password: 'Use it for login PW',
        select_a_file: 'Select a file',
        delete_selected_image: 'Deleting registered images',
        user_for_icon_image: 'Use it as an icon image in the system',
        create_subdomain_label: 'Create sub-domain',
        office_user_icon: 'Icon',
        unique_subdomain: 'Subdomain alredy exist',
        unique_email: 'Email already exist',
        new_data_has_been_saved: 'New data has been saved.',
      },
    },
    product: {
      labels: {
        product_type_create: 'ProductList type Creation',
        amount_of_money: 'Amount of money',
        merchandise_management: 'Merchandise management',
        number_only: 'Number only Ex: 30000',
      },
      delete_confirmation: 'Are you sure you want to delete?',
      delete_success_message: 'The deletion is complete.',
      breadcrumbs: {
        home: 'Home',
        environment_setting: 'Environment settings',
        product_type_settings: 'Product type settings',
      },
      form: {
        product_type: 'Product type',
        submit_button: 'Creature ',
        already_registered: 'Already register',
      },
    },
    client: {
      labels: {
        account_setting: 'Account setting',
        customer_information: 'Customer Information',
      },
      table_header: {
        customer_number: 'Customer number',
        account_name: 'Account name',
        contact: 'Contact',
        phone_number: 'Phone number',
        email_address: 'Email Adress',
        address: 'Address',
      },
      form: {
        company_name: 'Company name',
        name_furigana: 'Company name: Furigana',
        zip_code: 'Zip code',
        please_register_only:
          'Please register only if your billing address is different from your basic settings',
        billing_information: 'Billing information',
        please_include_full_name: 'Please include full name',
        title_of_honor: 'Title of honor',
        use_it_for_documents: 'Use it for documents',
        department: 'Department',
        person_in_charge_corp: 'Person in charge of the company',
        name_of_person_in_charge: 'Name of person in charge',
        quotation_invoice: 'Quotation invoice',
        customer_number_not_exist: 'This customer number does not exist',
        save: 'Save',
      },
      csv: {
        title: 'Customer CSV Import',
        save: 'Import',
        success: 'Imported {{valid}} out of {{total}} rows。',
        error: {
          empty_field: '{{field}} is a required field。',
          duplicate_field: '{{field}} is duplicated。',
          exceeded_file_size: 'Please attach CSV (max 10MB)。',
          invalid_file: 'Please register in the correct CSV format.',
          incorrect_format: 'Incorrect field {{field}}。',
          check_content:
            'Please check for blanks, duplicates, incorrect formatting (zip code, phone number, email, etc.) and correct any errors。',
        },
        filename: '顧客_サンプルCSV.csv',
      },
    },
  },
};

export default en;
